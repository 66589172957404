import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const SuccessInvitationPage = props => {
    const { state } = props.location;
    const name = state ? state.name : '';

    useEffect(() => {
        if (!props.location.state) {
            navigate('/');
        }
    }, []);
    return (
        <Layout>
            <SEO title="Aguaman - Invitación Exitosa" pathname="/invitacion-exitosa" />
            <section className="invitation-prop">
                <div className="invitation-success-prop-content">
                    <div className="invitation-success-message">
                        <div className="success-envelope-image"></div>
                        <h1>¡Valdrá la pena!</h1>
                        <p className="invitation-text">
                            Descarga la app y pide tu <b> primera recarga</b> para que{' '}
                            {name.split(' ')[0]} se entere que aceptaste su invitación.
                        </p>
                        <div className="invitation-success-buttons">
                            <div>
                                <a
                                    className="button"
                                    href="https://play.google.com/store/apps/details?id=com.aguaman.app"
                                >
                                    <div className="logo-google-play"></div>&nbsp; Google Play
                                </a>
                            </div>
                            <div>
                                <a
                                    className="button"
                                    href="https://apps.apple.com/kz/app/aguaman/id1541778190"
                                >
                                    <div className="logo-app-store"></div>&nbsp; App store
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default SuccessInvitationPage;
